import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";
import { IconMenu2 } from "@tabler/icons-react";

// project imports
import LogoSection from "../LogoSection";
import NavigationMenu from "components/NavigationMenu";
import inline_menu from "menu-items/inline_menu";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  function menuIcon(onLeftSide) {
    return (
      <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: "all .2s ease-in-out",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            "&:hover": {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
            display: onLeftSide
              ? { xs: "none", md: "flex" }
              : { xs: "flex", md: "none" },
          }}
          onClick={handleLeftDrawerToggle}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    );
  }

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="span">
          <LogoSection />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {menuIcon(false)}

      <NavigationMenu
        sx={{ display: { xs: "none", md: "flex" } }}
        groups={inline_menu}
      />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
