import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "layout/Loadable";
import Cache from "data/cache";

import disclaimersMd from "pages/legal/disclaimers.md";
import privacyMd from "pages/legal/privacy.md";
import termsMd from "pages/legal/terms.md";

// Page routing
const HomePage = Loadable(lazy(() => import("pages/home")));
const Experiences = Loadable(lazy(() => import("pages/experiences")));
const Blog = Loadable(lazy(() => import("pages/blog")));
const Testimonials = Loadable(lazy(() => import("pages/testimonials")));
const TexasVoterTracker = Loadable(
  lazy(() => import("pages/storyboards/texas_voter_tracker"))
);
const MadeToUplift = Loadable(
  lazy(() => import("pages/storyboards/made_to_uplift"))
);
const WIPPage = Loadable(lazy(() => import("pages/wip")));
const MarkdownPage = Loadable(lazy(() => import("pages/markdown")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <HomePage />,
    },

    // CAREER
    {
      path: "professional",
      element: (
        <Experiences
          category="Work"
          title="Professional Experience"
          subtitle="I began my career in Big Tech, but my long-term goal is to use my tech skills to launch my own social impact enterprise."
          fetcher={Cache.fetchExperiences}
        />
      ),
    },
    {
      path: "impact",
      element: (
        <Experiences
          category="Social Impact"
          title="Social Impact"
          subtitle="A sampling of my community engagement work, from volunteering to larger endeavors"
          fetcher={Cache.fetchExperiences}
        />
      ),
    },
    {
      path: "awards",
      element: (
        <Experiences
          category="Awards"
          title="Awards"
          subtitle="(non-exhaustive)"
          fetcher={Cache.fetchAwards}
        />
      ),
    },
    {
      path: "testimonials",
      element: <Testimonials />,
    },
    {
      path: "resumes",
      element: <WIPPage />,
    },
    {
      path: "timeline",
      element: <WIPPage />,
    },

    // PORTFOLIO
    {
      path: "portfolio",
      element: (
        <Experiences
          category="Portfolio"
          title="Full Portfolio"
          subtitle="Passion projects makes me really happy :)"
          disclaimers={[
            "I love using my skills to solve problems that either I or others are facing. Below, you'll find a sampling of the projects that I've worked on over the years.",
            "My intention is show you that I love to tinker and build — my passion projects often make me so excited that I pull caffeine-free all-nighters to work on them.",
          ]}
          fetcher={Cache.fetchExperiences}
        />
      ),
    },
    {
      path: "2020-tracker",
      element: (
        <TexasVoterTracker year={2020} subtitle="The Original Release" />
      ),
    },
    {
      path: "2022-tracker",
      element: <TexasVoterTracker year={2022} subtitle="The Latest Release" />,
    },
    {
      path: "2022-tracker",
      element: <WIPPage />,
    },
    {
      path: "made-to-uplift",
      element: <MadeToUplift />,
    },
    {
      path: "uplift-journal",
      element: <WIPPage />,
    },
    {
      path: "memories",
      element: <WIPPage />,
    },

    // BLOG
    {
      path: "blog/hbs",
      element: (
        <Blog
          category="mba"
          title="Harvard Business School"
          subtitle="A series of entries from my MBA experience at Harvard"
        />
      ),
    },
    {
      path: "blog/tech",
      element: (
        <Blog
          category="tech"
          title="Tech"
          subtitle="Entries ranging from my work experience to my various passion projects and how they helped me break into tech"
        />
      ),
    },
    {
      path: "blog/musings",
      element: (
        <Blog
          category="life"
          title="Musings"
          subtitle="Miscellaneous thoughts on life"
        />
      ),
    },

    // LEGAL
    {
      path: "disclaimers",
      element: <MarkdownPage file={disclaimersMd} />,
    },
    {
      path: "privacy",
      element: <MarkdownPage file={privacyMd} />,
    },
    {
      path: "terms",
      element: <MarkdownPage file={termsMd} />,
    },

    // HOBBIES
    {
      path: "alaska",
      element: <WIPPage />,
    },
    {
      path: "travel",
      element: <WIPPage />,
    },
    {
      path: "dance",
      element: <WIPPage />,
    },
    {
      path: "guitar",
      element: <WIPPage />,
    },
    {
      path: "quotes",
      element: <WIPPage />,
    },
  ],
};

export default MainRoutes;
