import _ from "lodash";
import moment from "moment";

export default class BlogEntry {
  constructor(data) {
    this.title = _.get(data, "title");
    this.public = _.get(data, "public", false);
    this.date = _.get(data, "date");
    this.link = _.get(data, "link");
    this.categories = _.get(data, "categories", []);
    this.tags = _.get(data, "tags", []);
    this.length = _.get(data, "length", {});
    this.hits = _.get(data, "hits", 0);
  }

  get dateFormatted() {
    return moment(this.date).format("MMM DD, YYYY");
  }

  get minutes() {
    return _.get(this.length, "minutes", -5);
  }
}
