import _ from "lodash";

import { IconBrandReddit, IconTrophy } from "@tabler/icons-react";

export default class AppTestimony {
  constructor(id, data) {
    this.id = id;

    this.comments = _.get(data, "comments");
    this.fromReddit = _.get(data, "reddit", false);
    this.hasAwards = _.get(data, "awards", false);
    this.upvotes = _.max([_.get(data, "upvotes", 1), 1]);
    this.username = _.get(data, "username");
  }

  get caption() {
    if (_.has(this.comments, "succinct")) {
      return _.join(this.comments.succinct, ". ");
    }
    return _.join(this.comments.original, ". ");
  }

  get colorScheme() {
    if (this.fromReddit) {
      if (this.hasAwards) {
        return {
          backgroundColor: "#ffe57f",
          color: "#ff8f00",
        };
      }
      return {
        backgroundColor: "#FF5700",
        color: "white",
      };
    }
    return null;
  }

  get icon() {
    if (this.fromReddit) {
      if (this.hasAwards) {
        return IconTrophy;
      }
      return IconBrandReddit;
    }
    return null;
  }

  get likes() {
    if (this.hasAwards) {
      return _.max([0, this.hasAwards.length]);
    }
    return _.max([0, this.upvotes]);
  }

  get name() {
    if (this.fromReddit) {
      if (this.hasAwards) {
        return `- Reddit`;
      }
      return `- Reddit user`;
    }
    return "test";
  }

  get score() {
    const lengthOfMessage = this.caption.length;
    const multiplier = this.hasAwards ? this.hasAwards.length : this.upvotes;
    return lengthOfMessage * multiplier;
  }
}
