import _ from "lodash";
import Firestore from "./firestore";
import TVTFirestore from "./tvt_firestore";

const CACHE = {
  signIn: _.memoize(Firestore.AnonymouslySignIn),
  fetchAwards: _.memoize(Firestore.fetchAwards),
  fetchBlog: _.memoize(Firestore.fetchBlog),
  fetchExperiences: _.memoize(Firestore.fetchExperiences),
  fetchHighlightedExperiences: _.memoize(Firestore.fetchHighlightedExperiences),
  fetchStoryboard: _.memoize(Firestore.fetchStoryboard),
  fetchTestimonies: _.memoize(Firestore.fetchTestimonies),
  fetchTVTImpact: _.memoize(TVTFirestore.Impact),
  fetchTVTTestimonies: _.memoize(TVTFirestore.Testimonies),
};

export default class Cache {
  static async AnonymouslySignIn() {
    return CACHE.signIn();
  }

  static async fetchAwards() {
    await Cache.AnonymouslySignIn();
    return CACHE.fetchAwards();
  }

  static async fetchBlog(category) {
    await Cache.AnonymouslySignIn();
    return CACHE.fetchBlog(category);
  }

  static async fetchExperiences(category) {
    await Cache.AnonymouslySignIn();
    return CACHE.fetchExperiences(category);
  }

  static async fetchHighlightedExperiences() {
    await Cache.AnonymouslySignIn();
    return CACHE.fetchHighlightedExperiences();
  }

  static async fetchStoryboard(story) {
    await Cache.AnonymouslySignIn();
    return CACHE.fetchStoryboard(story);
  }

  static async fetchTestimonies() {
    await Cache.AnonymouslySignIn();
    return CACHE.fetchTestimonies();
  }

  static async fetchTVTImpact() {
    return CACHE.fetchTVTImpact();
  }

  static async fetchTVTTestimonies() {
    return CACHE.fetchTVTTestimonies();
  }
}
