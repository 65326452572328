import _ from "lodash";

const ratings = {
  4: "Extremely Impactful",
  3: "Highly Impactful",
  2: "Very Impactful",
  1: "Impactful",
};

export default class Ratings {
  static all() {
    return _.map(ratings, (string, lvl) => `${lvl}: ${string}`)
      .sort()
      .reverse();
  }
  static determineLevel(string) {
    return _.parseInt(string[0]);
  }

  static numberToStr(number) {
    return ratings[number];
  }
}
