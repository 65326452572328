import { IconSpeakerphone } from "@tabler/icons-react";

import experience from "./experience";
import portfolio from "./portfolio";
// import hobbies from "./hobbies";

const menuItems = {
  items: [
    {
      id: "top-level",
      type: "group",
      children: [
        {
          id: "testimonials",
          title: "Testimonials",
          type: "item",
          url: "/testimonials",
          icon: IconSpeakerphone,
          breadcrumbs: false,
          color: "#00a0b2", // dark cyan
        },
      ],
    },
    experience,
    portfolio,
    // hobbies,
  ],
};

export default menuItems;
