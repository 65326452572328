import _ from "lodash";
import moment from "moment";

export default class ExperienceWrapper {
  constructor(data) {
    this.title = _.get(data, "title");
    this.subtitle = _.get(data, "subtitle");
    this.avatar = _.get(data, ["media", "avatar"]);
    this.images = _.get(data, ["media", "images"], []);
    this.dates = _.get(data, "dates");
    this.link = _.get(data, "link");
    this.description = _.get(data, "description");
    this.additional = _.get(data, "additional");
    this.chips = _.get(data, "chips");
    this.ranking = _.get(data, "ranking");
    this.highlighted = _.get(data, "highlighted", false);
  }

  get displayedDate() {
    const startMonth = moment(this.dates.start).format("MMM");
    const startYear = moment(this.dates.start).format("YYYY");
    const startString = `${startMonth} ${startYear}`;
    const endMonth = this.dates.end
      ? moment(this.dates.end).format("MMM")
      : "Present";
    const endYear = this.dates.end ? moment(this.dates.end).format("YYYY") : "";
    const endString = `${endMonth} ${endYear}`;

    if (startString === endString) {
      return startString;
    }
    if (startYear === endYear) {
      return `${startMonth} - ${endMonth} ${endYear}`;
    }
    return `${startString} - ${endString}`;
  }
}
