import _ from "lodash";
import AnalyticsAPI from "analytics";
import simpleAnalyticsPlugin from "@analytics/simple-analytics";
import emailjs from "@emailjs/browser";

import Firestore from "./firestore";
import Logger from "./logger";

class AnalyticsWrapper {
  constructor() {
    this.simpleAnalytics = AnalyticsAPI({
      app: "shivalicious",
      plugins: [
        simpleAnalyticsPlugin({
          customDomain: "api.shivalicious.com",
          hostname: "shivalicious.com",
        }),
      ],
    });

    // Determine new vs returning user
    const identifier = localStorage.getItem("Hello");
    this.oneMore("users", "all");
    if (!identifier) {
      localStorage.setItem("Hello", "user! :)");
      this.oneMore("users", "new");
    }
    Logger.transparency(
      `Welcome ${identifier ? "back " : ""}to my site, ${
        identifier ? "" : "new "
      }friend!`
    );

    // Determine if UTM source brought them here
    const urlHelper = new URL(window.location.href);
    const utm_source = urlHelper.searchParams.get("utm_source");
    if (utm_source) {
      this.oneMore("utm_source", utm_source);
    }
  }

  async pageView(pageName) {
    if (Logger.mode().inDebugMode) {
      Logger.debug(`Page loaded: ${pageName}`);
      return;
    }
    this.simpleAnalytics.page();
    Firestore.Event("Page_Loaded", pageName);
    this.trackViaEmail("Page_Loaded", pageName);
  }

  async oneMore(event, subtype) {
    if (Logger.mode().inDebugMode) {
      Logger.debug(`Incrementing events: ${event} (${subtype})`);
      return;
    }
    this.simpleAnalytics.track(event);
    Firestore.Event(event, subtype);
    this.trackViaEmail(event, subtype);
  }

  async trackViaEmail(event, subtype) {
    if (!Logger.mode().allowEmail) {
      return;
    }
    const SERVICE_ID = "service_6gbywwc";
    const TEMPLATE_ID = "template_k4ec5dy";
    const PUBLIC_KEY = "3INnG7OCeGqWStO1I";

    emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      {
        url: _.toLower(window.location.href),
        activity: event,
        on: subtype,
      },
      PUBLIC_KEY
    );
  }
}

const Analytics = new AnalyticsWrapper();
export default Analytics;
